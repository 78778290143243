import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import moment from 'moment'

import Layout from '../components/Layout/Layout'

import * as styles from './updatePageTemplate.module.css'

const UpdatePageTemplate = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const image = getImage(frontmatter.photo.photo)

  return (
    <Layout>
      <Helmet>
        <title>{`${frontmatter.title} | MS Votes`}</title>
        {frontmatter.canonicalUrl ? <link rel="canonical" href={frontmatter.canonicalUrl} /> : null}
      </Helmet>
      <div className="main">
        <div className={styles.blog_post}>
          {frontmatter.photo && <GatsbyImage image={image} alt={frontmatter.photo.altText} />}
          <h1>{frontmatter.title}</h1>
          <small><strong>{frontmatter.author}</strong></small><br />
          <small>{frontmatter.newsOutlet ? <><a href={frontmatter.canonicalUrl}>{frontmatter.newsOutlet}</a> &mdash; </> : null}{moment(frontmatter.publishDate).format('MMMM DD, YYYY')}</small>
          <section dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        publishDate
        newsOutlet
        title
        author
        canonicalUrl
        photo {
          photo{
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          altText
        }
      }
    }
  }
`


export default UpdatePageTemplate